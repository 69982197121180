import React from "react"
import { Heading } from "@chakra-ui/react"

const sizeVals = {
  h1: "2xl",
  h2: "xl",
  h3: "lg",
  h4: "md",
  h5: "sm",
  h6: "xs",
}

const HeadingComp = (props) => {
  return (
    <Heading
      size={sizeVals[props.as]}
      color={
        props.color ||
        (props.isLink
          ? "brand.blueColor"
          : props.isError
          ? "brand.orangeColor"
          : "brand.headingColor")
      }
      borderBottomColor={
        props.isLink ? "brand.blueColor" : "brand.headingColor"
      }
      borderBottomWidth={props.underline ? `1px` : 0}
      fontWeight={props.islight ? 500 : 700}
      my={1}
      {...props}
      // lineHeight={props.size < 4 ? 1.3 : 1.5}
      fontSize={props.fontSize}
    >
      {props.children}
    </Heading>
  )
}

export default HeadingComp
