// import { mapAccessToken } from '../config/map'
import {
  faInstagram,
  faYoutube,
  faVimeo,
} from "@fortawesome/free-brands-svg-icons"
import { imageHandler } from "./imageHandler"

export const dataURLtoBlob = (dataURI) => {
  const binary = atob(dataURI.split(",")[1])
  const array = []
  for (let i = 0; i < binary.length; i += 1) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" })
}

export function getYoutubeId(url) {
  let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
  return regex.exec(url)[3]
}

function getPopMediaObj(popMedia) {
  return popMedia.video
    ? { video: popMedia.video }
    : popMedia.oembed?.url
    ? popMedia.oembed.provider_name === "Instagram"
      ? popMedia.oembed.thumbnail_url
        ? // instagram's thumbnail_urls expire
          {
            title: popMedia.oembed.title,
            url: popMedia.oembed.url,
            // thumbnailUrl: popMedia.oembed.url.split("?")[0] + "media?size=l",
            thumbnailUrl: popMedia.oembed.thumbnail_url,
            providerName: popMedia.oembed.provider_name,
            authorUrl: popMedia.oembed.author_url,
            authorName: popMedia.oembed.author_name,
            pebbleUrl:
              popMedia.type === "media" ? `/media/${popMedia.slug}` : null,
          }
        : null
      : popMedia.oembed.provider_name === "YouTube" ||
        popMedia.oembed.provider_name === "Vimeo" ||
        popMedia.oembed.provider_name === "Instagram"
      ? {
          title: popMedia.oembed.title,
          thumbnailUrl: popMedia.oembed.thumbnail_url,
          url: popMedia.oembed.url,
          providerName: popMedia.oembed.provider_name,
          authorUrl: popMedia.oembed.author_url,
          authorName: popMedia.oembed.author_name,
          pebbleUrl:
            popMedia.type === "media"
              ? `pebble://media/${popMedia.slug}`
              : null,
        }
      : {
          thumbnailUrl: popMedia.oembed.thumbnail_url,
          url: popMedia.oembed.url,
        }
    : popMedia.url
    ? {
        url: popMedia.url,
        thumbnailUrl: popMedia.url,
      }
    : popMedia.image
    ? {
        url: popMedia.image,
        thumbnailUrl: popMedia.image,
      }
    : null
  // popMedia.url
}

export const getSocialIcon = (iconName) => {
  switch (iconName) {
    case "Instagram":
      return faInstagram
    case "YouTube":
      return faYoutube
    case "Vimeo":
      return faVimeo
    default:
  }
}

export const getPopMediaThumb = (feature, isShowingPicture) => {
  if (!feature) {
    return null
  }
  return feature.oembed?.url || feature.video
    ? getPopMediaObj(feature)
    : feature.popMedia
    ? getPopMediaObj(feature.popMedia)
    : feature.problem && feature.problem.popMedia
    ? getPopMediaObj(feature.problem.popMedia)
    : feature.route && feature.route.popMedia
    ? getPopMediaObj(feature.route.popMedia)
    : isShowingPicture
    ? feature.picture
      ? { url: feature.picture, thumbnailUrl: feature.picture }
      : feature.problem
      ? { url: feature.problem.picture, thumbnailUrl: feature.problem.picture }
      : feature.route
      ? { url: feature.route.picture, thumbnailUrl: feature.route.picture }
      : null
    : null
}

// export const getMediaOrMapThumb = (feature, width = 500) => {
//   return (
//     getPopMediaThumb(feature) ||
//     'https://api.mapbox.com/styles/v1/mapbox/satellite-v8/static/' +
//       feature.location.coordinates[0] +
//       ',' +
//       feature.location.coordinates[1] +
//       ',16.5,0.00/' +
//       Math.round(width) +
//       'x100@2x?access_token=' +
//       mapAccessToken
//   )
// }

export const getClimbImage = ({
  climb,
  isOnline = true,
  isOutdoor,
  width,
  height,
  isInRow,
}) => {
  if (!climb) {
    return null
  }
  try {
    const popMedia = getPopMediaThumb(climb)
    if (!!popMedia && !popMedia?.image && isOnline && isOutdoor && !isInRow) {
      const path = popMedia.thumbnailUrl || popMedia?.oembed?.thumbnail_url
      return imageHandler(path, {
        resize: { width, height, fit: "cover" },
      })
    }

    if (!!climb.overviewPicture && (!isInRow || !climb.picture)) {
      return imageHandler(climb.overviewPicture, {
        resize: { width, height, fit: "cover" },
      })
    }
    if (!!climb.picture) {
      if (climb.picture.includes("wikiboulder")) {
        return climb.picture
      }
      return imageHandler(climb.picture, {
        resize: { width, height, fit: "cover" },
      })
    }
    if (!!climb?.popMedia?.image) {
      return imageHandler(climb?.popMedia?.image, {
        resize: { width, height, fit: "cover" },
      })
    }
    // finally, if in row and didn't find an overview picture, use the climb picture
    if (isInRow && isOnline && isOutdoor && !!popMedia) {
      return popMedia.thumbnailUrl || popMedia?.oembed?.thumbnail_url
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

// because btoa is not working
// Inspired by: https://github.com/davidchambers/Base64.js/blob/master/base64.js
const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
export const Base64 = {
  btoa: (input = "") => {
    let str = input
    let output = ""

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = "="), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4))

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.",
        )
      }

      block = (block << 8) | charCode
    }

    return output
  },

  atob: (input = "") => {
    let str = input.replace(/=+$/, "")
    let output = ""

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded.",
      )
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer)
    }

    return output
  },
}

