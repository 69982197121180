/**
 *  Converts an S3 url to a Cloudfront URL and URL encodes the
 *  given `edits` object for processing the through an AWS Serverless
 *  Image Handler
 *
 *  More info at:
 *  https://aws.amazon.com/solutions/implementations/serverless-image-handler/
 *
 *  You can play around with the `edits` options here:
 *  https://dyl4980h5stmg.cloudfront.net/index.html#
 */
import _ from "lodash"
import { Base64 } from "./imageUtils"

const BUCKETS = [
  "problem-lines",
  "route-lines",
  "gym-brand-images",
  "profiles-asend",
  "pebble-images",
]

const ENDPOINT = "https://d2s2fbeelce7po.cloudfront.net"

const getBaseRequest = (url) => {
  let base
  _.forEach(BUCKETS, (bucket) => {
    if (_.includes(url.hostname, bucket)) {
      base = {
        bucket,
        key: _.trimStart(url.pathname, "/"),
      }
    }
    if (_.includes(url.pathname, bucket)) {
      base = {
        bucket,
        key: _.replace(url.pathname, `/${bucket}/`),
      }
    }
  })
  return base
}

export const imageHandler = (s3url, edits) => {
  if (!s3url) {
    return s3url
  }
  // make sure resize values are round numbers
  if (edits?.resize?.width) {
    edits.resize.width = Math.ceil(edits.resize.width)
  }
  if (edits?.resize?.height) {
    edits.resize.height = Math.ceil(edits.resize.height)
  }
  try {
    const parsedUrl = new URL(s3url)

    if (!parsedUrl) {
      return s3url
    }

    const base = getBaseRequest(parsedUrl)

    if (!base) {
      return s3url
    }

    const request = !!edits
      ? {
          ...base,
          edits: {
            jpeg: {
              quality: 80,
            },
            ...edits,
          },
        }
      : base
    const str = JSON.stringify(request)
    const enc = typeof btoa === "function" ? btoa(str) : Base64.btoa(str)
    return `${ENDPOINT}/${enc}`
  } catch (error) {
    console.log("s3url: ", s3url)
    console.log("error: ", error)
    return s3url
  }
}

export const getResizedImageObj = (imageObj, dimensions) => {
  if (
    !imageObj?.url ||
    imageObj.url.includes("amazonaws.com") === false ||
    imageObj.url.includes("wikiboulder") === true
  ) {
    return imageObj
  }

  const url = imageHandler(imageObj?.url, {
    resize: { ...dimensions, fit: "cover" },
  })

  return { ...imageObj, url: url, thumbnailUrl: url }
}
