import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Box, Image, useTheme } from "@chakra-ui/react"
import { getSocialIcon } from "../../utilities/imageUtils"
import Heading from "./Heading"
import useColors from "../../common/hooks/useColors"

const SocialImage = ({
  imageObj: {
    title = "",
    thumbnailUrl = "",
    url = "",
    providerName = "",
    authorUrl = "",
    authorName = "",
  } = {},
  height = "auto",
  width = "auto",
  isSmall = false,
  isContain = false,
  bg = null,
}) => {
  const { blueColor, whiteColor } = useColors()
  const [isShowingSocial, toggleShowSocial] = useState(false)
  return (
    <Box
      className="social-image"
      position="relative"
      height={height}
      width={width}
      overflow="hidden"
      onMouseLeave={() => {
        toggleShowSocial(false)
      }}
      onClick={(e) => {
        if (isShowingSocial) {
          e.stopPropagation()
        }
      }}
    >
      {isContain && !bg && (
        <Box
          alignItems="center"
          justifyContent="center"
          filter="blur(10px) brightness(0.5)"
          position="absolute"
          top={-2}
          left={-2}
          right={-2}
          bottom={-2}
          bg="brand.blueBackground"
        >
          <Image
            src={thumbnailUrl || url}
            alt={title || "image"}
            height={height}
            width={width}
            objectFit="cover"
          />
        </Box>
      )}
      {!!bg && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={bg}
        />
      )}
      <Box position="relative" height="100%" width="100%">
        {!!isContain && !bg && (
          <Box position="absolute" top={0} left={0} right={0} bottom={0}>
            <Image
              src={thumbnailUrl || url}
              alt={title || "image"}
              height={height}
              width={width}
              objectFit={"cover"}
              style={{ filter: "blur(10px)" }}
            />
          </Box>
        )}
        <Image
          position={"relative"}
          src={thumbnailUrl || url}
          alt={title || "image"}
          height={height}
          width={width}
          objectFit={isContain ? "contain" : "cover"}
        />
      </Box>
      {providerName && (
        <>
          <Box
            position="absolute"
            bottom={isSmall ? 0 : 2}
            left={isSmall ? 1 : 3}
          >
            <Box
              position="relative"
              opacity={0.8}
              onMouseEnter={() => {
                toggleShowSocial(true)
              }}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.nativeEvent.stopImmediatePropagation()
                  window.open(url)
                  return false
                }}
              >
                <FontAwesomeIcon
                  size="2x"
                  color={isShowingSocial ? blueColor : whiteColor}
                  icon={getSocialIcon(providerName)}
                />
              </div>
            </Box>
          </Box>
          {isShowingSocial && !isSmall && (
            <Box
              fadeIn
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              bg="overlay"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box p={4} width="100%">
                {title && (
                  <Heading
                    as={"h3"}
                    color="brand.whiteColor"
                    textAlign="center"
                  >
                    {title.substr(0, 30)}
                  </Heading>
                )}
                {authorUrl || authorName ? (
                  <Box display="flex">
                    <Box pr={2} minWidth="25%">
                      <Heading
                        mb={1}
                        size="h5"
                        color="brand.whiteColor"
                        textAlign="right"
                      >
                        Author:{" "}
                      </Heading>
                    </Box>
                    <Box flex={1} minWidth={0}>
                      <Heading mb={1} trunc size="h5" variant="link">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={authorUrl}
                        >
                          {authorName || authorUrl}
                        </a>
                      </Heading>
                    </Box>
                  </Box>
                ) : null}
                {providerName && (
                  <Box display="flex">
                    <Box pr={2} minWidth="25%">
                      <Heading
                        mb={1}
                        size="h5"
                        color="brand.whiteColor"
                        textAlign="right"
                      >
                        Provider:{" "}
                      </Heading>
                    </Box>
                    <Box flex={1} minWidth={0}>
                      <Heading mb={1} size="h5" variant="link">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://${providerName.toLowerCase()}.com`}
                        >
                          {providerName}
                        </a>
                      </Heading>
                    </Box>
                  </Box>
                )}
                {url && (
                  <Box display="flex">
                    <Box pr={isSmall ? 1 : 2} minWidth="25%">
                      <Heading
                        mb={1}
                        size="h5"
                        color="brand.whiteColor"
                        textAlign="right"
                      >
                        Link:{" "}
                      </Heading>
                    </Box>
                    <Box flex={1} minWidth={0}>
                      <Heading mb={1} trunc size="h5" variant="link">
                        <a target="_blank" rel="noopener noreferrer" href={url}>
                          {url}
                        </a>
                      </Heading>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default SocialImage
